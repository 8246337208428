.footer {
  background-color: $darkgreen;
  color: $white;

  //margin-top: 2rem;
  padding-top: 1rem;
  padding-bottom: 0.5rem;

  a {
    color: $white;
    padding-left: 0.2rem;
    padding-right: 0.2rem;

    &:hover {
      color: darken($white, 20%);
    }
  }

  .btn {
    color: #444444;
    background-color:#dadada;

    &:hover {
      color: $gray-900;
    }
  }
}

.footer__links {
  display: flex;
  justify-content: flex-end;
  align-items: end;


  .btn {
    margin-left: 1rem;
  }
}

.footer__socials {
  display: flex;
  height: 2rem;

  a {
    display: block;

    span {
      padding: 3px 3px 2px 4px;
      font-size: 70%;
      background: #fff;
      border-radius: 3px;
      display: block;
      overflow: hidden;
      color: #0b3117;
      margin: 2px 0 1px 0;

    }
    &:hover span {
      background: #cccccc;
    }
  }
}
